import React, { useEffect } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useTheme } from '@material-ui/core';
import connect from '../connect';
import { ROUTE_ONBOARDING_MEETING } from '../../../constants';
import { useHistory, useParams } from 'react-router-dom';

function OnboardingMeetingCalendar({ authUser, appTrainingUrl, sapTrainingUrl, saveMeetingInvitation }) {
  const theme = useTheme();
  const history = useHistory();
  const [calendlyUrl, setCalendlyUrl] = React.useState(null);
  const [eventType, setEventType] = React.useState(null);
  const { calendarSlug } = useParams();

  useEffect(() => {
    let newUrl = null;
    if (appTrainingUrl?.includes(calendarSlug)) {
      newUrl = appTrainingUrl;
      setEventType('appTraining');
    } else if (sapTrainingUrl?.includes(calendarSlug)) {
      newUrl = sapTrainingUrl;
      setEventType('sapTraining');
    }

    if (!newUrl) {
      history.push(ROUTE_ONBOARDING_MEETING.path);
    }
    setCalendlyUrl(newUrl);
  }, [appTrainingUrl, sapTrainingUrl, calendarSlug, history]);

  useEffect(() => {
    const widget = document.querySelector('.calendly-inline-widget');
    if (widget) {
      widget.style.height = '100%';
    }
  }, [calendlyUrl]);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      const { event, invitee } = e?.data?.payload;
      if (!event || !invitee) {
        return;
      }
      saveMeetingInvitation({
        eventUri: event.uri,
        inviteeUri: invitee.uri,
        eventType,
      });
      history.push(ROUTE_ONBOARDING_MEETING.path);
    },
  });

  return (
    <>
      {calendlyUrl && (
        <InlineWidget
          url={calendlyUrl}
          pageSettings={{
            backgroundColor: 'ffffff',
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: theme.palette.primary.main.split('#')[1],
            textColor: theme.palette.text.primary.split('#')[1],
          }}
          prefill={{
            email: authUser.email,
            name: `${authUser.name} ${authUser.familyName}`,
          }}
        />
      )}
    </>
  );
}

export default connect(OnboardingMeetingCalendar);
