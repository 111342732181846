import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_ONBOARDING_MEETING } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const OnboardingMeeting = React.lazy(() => import('./OnboardingMeeting'));
const OnboardingMeetingCalendar = React.lazy(() => import('./Calendar'));

export default function OnboardingMeetingWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_ONBOARDING_MEETING.path}>
          <OnboardingMeeting />
        </Route>

        <Route exact path={ROUTE_ONBOARDING_MEETING.path + '/:calendarSlug'}>
          <OnboardingMeetingCalendar />
        </Route>

        <Redirect exact path="/*" to={ROUTE_ONBOARDING_MEETING.path} />
      </Switch>
    </Suspense>
  );
}
