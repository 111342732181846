import { batch, connect } from 'react-redux';
import { actions } from '../../store/onboardingMeeting';

function mapStateToProps(state) {
  return {
    settings: state.orgSettings,
    authUser: state.auth,
    subscription: state.subscription,
    appTrainingUrl: state.onboardingMeeting.appTrainingUrl,
    sapTrainingUrl: state.onboardingMeeting.sapTrainingUrl,
    pending: state.onboardingMeeting.pending,
    scheduledMeetings: state.onboardingMeeting.scheduledMeetings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: async () => {
      await dispatch(actions.getCalendlyUrl());
      await dispatch(actions.getScheduledMeetings());
    },
    getCalendlyUrl: () => dispatch(actions.getCalendlyUrl()),
    getScheduledMeetings: () => dispatch(actions.getScheduledMeetings()),
    saveMeetingInvitation: (params) => dispatch(actions.saveMeetingInvitation(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
