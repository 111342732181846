export function translateApiMessage(trans, message, values) {
  const defaultTitle = 'Unknown message';
  if (typeof trans !== 'function') return { variant: 'info', title: defaultTitle };

  if (typeof message === 'string') {
    const props = API_MESSAGES[message] || {};

    return {
      variant: props.variant,
      title: props.title ? trans(props.title, values) : defaultTitle,
      message: props.message ? trans(props.message, values) : '',
      replace: props.replace || false,
    };
  }

  return {
    variant: message && message.variant ? String(message.variant) : 'info',
    title: message && message.title ? String(message.title) : defaultTitle,
    message: message && message.message ? String(message.message) : '',
  };
}

/**
 * variants - default :: error :: info :: success
 */

export const API_MESSAGES = {
  CSV_WORKER_START: {
    variant: 'info',
    title: 'csv-worker-title',
    message: 'preparing-csv',
  },
  CSV_WORKER_END: {
    variant: 'success',
    title: 'csv-worker-title',
    message: 'finish-csv',
    replace: true,
  },
  CSV_WORKER_NO_ITEMS: {
    variant: 'default',
    title: 'csv-worker-title',
    message: 'no-items-csv',
    replace: true,
  },
};
