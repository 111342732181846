import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconOnboardingMeeting = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.79001 7C6.55358 7 6.34831 7.00925 6.16404 7.03229C6.15112 7.0339 6.13817 7.03526 6.1252 7.03637C4.96208 7.13582 4.22991 7.51294 3.77313 8.05023C3.30542 8.60037 3 9.46663 3 10.79V14.79C3 16.704 3.39165 17.5258 3.8565 17.9318C4.35497 18.367 5.21142 18.58 6.79001 18.58H7.19C7.50361 18.58 7.78764 18.679 7.99482 18.7838C8.20263 18.8888 8.44257 19.0548 8.62434 19.2925L8.63002 19.3L9.83135 20.9018C9.88618 20.9753 9.93224 21.0075 9.95626 21.0206C9.97868 21.0327 9.98913 21.0325 9.98996 21.0325C9.99032 21.0325 10.0008 21.0331 10.0237 21.0206C10.0478 21.0075 10.0938 20.9753 10.1487 20.9018L10.15 20.9L11.35 19.3C11.6888 18.8482 12.2253 18.58 12.79 18.58H13.19C14.515 18.58 15.381 18.2763 15.9305 17.81C16.4666 17.3551 16.8441 16.6239 16.9436 15.4551C16.9447 15.4421 16.9461 15.429 16.9477 15.416C16.9708 15.2317 16.98 15.0264 16.98 14.79V10.79C16.98 9.34995 16.6196 8.45133 16.0742 7.90586C15.5287 7.36039 14.6301 7 13.19 7H6.79001ZM5.93578 5.04529C6.22487 5.01029 6.51277 5 6.79001 5H13.19C14.9399 5 16.4363 5.43961 17.4884 6.49165C18.5404 7.54368 18.98 9.04007 18.98 10.79V14.79C18.98 15.0672 18.9697 15.355 18.9347 15.6441C18.8015 17.1669 18.269 18.4486 17.2245 19.335C16.189 20.2137 14.795 20.58 13.19 20.58H12.89L11.7514 22.0982C11.7511 22.0986 11.7507 22.099 11.7504 22.0994C11.3268 22.6663 10.6997 23.0325 9.99001 23.0325C9.28024 23.0325 8.65308 22.6662 8.22944 22.0993C8.22918 22.0989 8.22891 22.0985 8.22865 22.0982L7.08998 20.58H6.79001C5.1786 20.58 3.64004 20.3979 2.541 19.4382C1.40835 18.4492 1 16.876 1 14.79V10.79C1 9.18339 1.36958 7.78964 2.24938 6.75479C3.1364 5.71143 4.41723 5.17864 5.93578 5.04529Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25594 5H13.19C14.9399 5 16.4363 5.43961 17.4884 6.49165C18.5404 7.54368 18.98 9.04007 18.98 10.79L18.98 14.3249C19.5244 14.1435 19.9205 13.8699 20.2074 13.5329C20.6743 12.9847 20.98 12.1191 20.98 10.79V6.79001C20.98 5.34995 20.6196 4.45133 20.0742 3.90586C19.5287 3.36039 18.6301 3 17.19 3H10.79C9.46663 3 8.60038 3.30542 8.05023 3.77313C7.71242 4.06033 7.43792 4.4564 7.25594 5ZM6.75479 2.24938C7.78965 1.36958 9.18339 1 10.79 1H17.19C18.9399 1 20.4363 1.43961 21.4884 2.49165C22.5404 3.54368 22.98 5.04007 22.98 6.79001V10.79C22.98 12.4009 22.6108 13.7954 21.7301 14.8296C20.8387 15.8765 19.5504 16.406 18.0252 16.5364C17.7246 16.5621 17.4284 16.4507 17.2192 16.2332C17.0101 16.0157 16.9103 15.7154 16.9477 15.416C16.9708 15.2317 16.98 15.0264 16.98 14.79V10.79C16.98 9.34995 16.6196 8.45133 16.0742 7.90586C15.5287 7.36039 14.6301 7 13.19 7H6.79001C6.55358 7 6.34831 7.00925 6.16404 7.03229C5.86463 7.06971 5.56434 6.96994 5.34685 6.76079C5.12936 6.55164 5.01794 6.25546 5.04365 5.95482C5.1742 4.42793 5.70708 3.1401 6.75479 2.24938Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4955 13.25C12.4955 12.6977 12.9412 12.25 13.491 12.25H13.5C14.0498 12.25 14.4955 12.6977 14.4955 13.25C14.4955 13.8023 14.0498 14.25 13.5 14.25H13.491C12.9412 14.25 12.4955 13.8023 12.4955 13.25Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.9955 13.25C8.9955 12.6977 9.44121 12.25 9.99102 12.25H9.99998C10.5498 12.25 10.9955 12.6977 10.9955 13.25C10.9955 13.8023 10.5498 14.25 9.99998 14.25H9.99102C9.44121 14.25 8.9955 13.8023 8.9955 13.25Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.4955 13.25C5.4955 12.6977 5.94121 12.25 6.49102 12.25H6.49998C7.04979 12.25 7.4955 12.6977 7.4955 13.25C7.4955 13.8023 7.04979 14.25 6.49998 14.25H6.49102C5.94121 14.25 5.4955 13.8023 5.4955 13.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

IconOnboardingMeeting.propTypes = simpleIconPropsTypes;
