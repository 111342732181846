import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';
import { onPending, onPendingDone } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  appTrainingUrl: null,
  sapTrainingUrl: null,
  scheduledMeetings: [],
  pending: false,
};

const getCalendlyUrl = createAsyncThunk(`onboardingMeeting/getCalendlyUrl`, (params) => {
  return application.call(API.ONBOARDING_MEETING.GET_CALENDLY_URL, params);
});
const onGetCalendlyUrl = (state, action) => {
  return Object.assign(state, {
    appTrainingUrl: action.payload.appTrainingUrl,
    sapTrainingUrl: action.payload.sapTrainingUrl,
    pending: false,
  });
};

const getScheduledMeetings = createAsyncThunk(`onboardingMeeting/getScheduledMeetings`, (params) => {
  return application.call(API.ONBOARDING_MEETING.GET_SCHEDULED_MEETINGS, params);
});
const onGetScheduledMeetings = (state, action) => {
  return Object.assign(state, {
    scheduledMeetings: action.payload,
    pending: false,
  });
};

const saveMeetingInvitation = createAsyncThunk(`onboardingMeeting/saveMeetingInvitation`, (params) => {
  return application.call(API.ONBOARDING_MEETING.SAVE_MEETING_INVITATION, params);
});

export const actions = {
  getCalendlyUrl,
  getScheduledMeetings,
  saveMeetingInvitation,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getCalendlyUrl.pending, onPending)
    .addCase(getCalendlyUrl.rejected, onPendingDone)
    .addCase(getCalendlyUrl.fulfilled, onGetCalendlyUrl)
    .addCase(getScheduledMeetings.pending, onPending)
    .addCase(getScheduledMeetings.rejected, onPendingDone)
    .addCase(getScheduledMeetings.fulfilled, onGetScheduledMeetings);
});
