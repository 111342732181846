export const ROUTE_SIGNUP = { key: 'sign-up', path: '/sign-up', noAuth: true };
export const ROUTE_VERIFY = { key: 'verification', path: '/verification', noAuth: true };
export const ROUTE_SIGNIN = { key: 'sign-in', path: '/sign-in', noAuth: true };
export const ROUTE_NO_ACCOUNT_FOUND = { key: 'no-account', path: '/no-account', noAuth: true };
export const ROUTE_CHANGEPASSWORD = { key: 'change-password', path: '/change-password', noAuth: true };
export const ROUTE_SETPASSWORD = { key: 'set-password', path: '/set-password', noAuth: true };
export const ROUTE_EXPIRATION = { key: 'expiration', path: '/expiration', noAuth: true };
export const ROUTE_PASSWORD_SUCCESS = { key: 'password-success', path: '/password-changed', noAuth: true };
export const ROUTE_UNSUBSCRIBE_EMAILS = {
  key: 'unsubscribe-emails',
  path: '/unsubscribe-emails',
  noAuth: true,
};
export const ROUTE_PLANS_PUBLIC = { key: 'plans', path: '/plans', noAuth: true };

export const ROUTE_PROFILE = { key: 'profile', path: '/profile' };
export const ROUTE_ORG_SETTINGS = { key: 'org-settings', path: '/org-settings' };
export const ROUTE_ORG_REGISTRATION_FORM = { key: 'register-organization', path: '/register-organization' };
export const ROUTE_ORG_TEAMING_FORM = { key: 'teaming-organization', path: '/teaming-organization' };
export const ROUTE_MIGRATION = { key: 'migration', path: '/extra-info' };
export const ROUTE_BILLING = { key: 'billing', path: '/billing' };
export const ROUTE_CHECKOUT = { key: 'checkout', path: '/checkout' };
export const ROUTE_SUCCESS_PAYMENT = { key: 'subscription-success', path: '/subscription-success/:subscriptionId' };
export const ROUTE_PLANS_PRIVATE = { key: 'plans-private', path: '/_plans' };

export const ROUTE_EZ_SEARCH = { key: 'search', path: '/search' };

//
export const ROUTE_DASHBOARD = {
  key: 'dashboard',
  path: '/dashboard',
  icon: 'IconDashboard',
  component: 'DashboardNew',
  subscribedOnly: true,
  showMenu: true,
  keyPermission: 'dashboard',
  onboardingDes: 'Here you will find a summary of your activity',
};

export const ROUTE_DEMO_DASHBOARD = {
  key: 'demo-dashboard',
  path: '/ddashboard',
  icon: 'IconDashboard',
  component: 'DemoDashboard',
  subscribedOnly: true,
  showMenu: true,
  keyPermission: 'demo-dashboard',
  onboardingDes: 'Here you will find a summary of your activity',
};
//
export const ROUTE_CALENDAR = {
  key: 'Calendar',
  path: '/calendar',
  icon: 'IconCalendar',
  component: 'Calendar',
  subscribedOnly: true,
  showMenu: true,
  keyPermission: 'calendar',
};

export const ROUTE_VIDEO_LIBRARY = {
  key: 'video-library',
  path: '/video-library',
  icon: 'IconVideo',
  component: 'VideoLibrary',
};

export const ROUTE_VIDEO_DETAIL = {
  key: 'video-detail',
  path: '/video-detail/:id',
  icon: 'IconVideo',
  component: 'VideoDetail',
};

export const ROUTE_SAVED_SEARCHES = {
  key: 'saved-searches',
  path: '/saved-searches',
  icon: 'IconSavedSearches',
  component: 'SavedSearches',
  showMenu: true,
  keyPermission: 'saved-searches',
  onboardingDes: 'Find all your saved searches in one place',
};

export const ROUTE_TEAMING_OPPORTUNITIES_DETAILS = {
  key: 'teaming-opportunities',
  path: '/teaming-opportunities/:id',
};

export const ROUTE_TEAMING_OPPORTUNITIES_EXPLORER = {
  key: 'teaming-opportunities',
  path: '/teaming-opportunities/browser/explorer',
};

export const ROUTE_OPEN_PURSUITS = {
  key: 'teaming-opportunities',
  path: '/open-pursuits',
  component: 'OpenPursuits',
};

export const ROUTE_TEAMING_OPPORTUNITIES = {
  key: 'teaming-opportunities',
  path: '/teaming-opportunities',
  icon: 'IconTeamingOpportunities',
  component: 'TeamingOpportunities',
  nested: [ROUTE_TEAMING_OPPORTUNITIES_EXPLORER, ROUTE_TEAMING_OPPORTUNITIES_DETAILS],
  showMenu: true,
  keyPermission: 'teaming-opportunities',
  onboardingDes: 'Find out if there are any opportunities to team up',
};

export const ROUTE_PURSUIT_DETAILS = {
  key: 'Pursuits',
  path: '/pursuits/:id',
};
export const ROUTE_MY_PURSUITS = {
  key: 'my-pursuits',
  path: '/pursuits',
  icon: 'IconPursuits',
  component: 'MyPursuits',
  subscribedOnly: true,
  showMenu: true,
  nested: [ROUTE_PURSUIT_DETAILS],
  keyPermission: 'my-pursuits',
  onboardingDes: "A list of your pursuits and the stage they're in",
};

export const ROUTE_PIPELINES = {
  key: 'pipelines',
  path: '/pipelines',
  icon: 'IconPipelines',
  component: 'Pipelines',
  subscribedOnly: true,
  showMenu: true,
  keyPermission: 'pipelines',
  onboardingDes: 'Create workflows for your pursuits and prospects',
};

export const ROUTE_TASKS = {
  key: 'pipelines-pursuits-tasks',
  path: '/tasks',
  icon: 'IconTasks',
  component: 'Tasks',
  subscribedOnly: true,
  showMenu: true,
  keyPermission: 'tasks',
  onboardingDes: 'Keep track of your work and pipelines with tasks',
};

export const ROUTE_PERSONAL_TASKS = {
  key: 'personal-tasks',
  path: '/personal-tasks',
  icon: 'IconPersonalTasks',
  component: 'PersonalTasks',
  subscribedOnly: true,
  showMenu: true,
  keyPermission: 'personal-tasks',
  onboardingDes: 'Create, edit, delete, and manage your personal tasks while keeping track of their progress',
};

export const ROUTE_VENDORS_DETAILS = {
  key: 'vendors',
  path: '/vendors/:id',
};
export const ROUTE_LOCAL_VENDORS_DETAILS = {
  key: 'local-vendors',
  path: '/vendors/local-vendors/:id',
};

export const ROUTE_VENDORS = {
  key: 'vendors',
  path: '/vendors',
  icon: 'IconVendors',
  component: 'Vendors',
  nested: [ROUTE_VENDORS_DETAILS, ROUTE_LOCAL_VENDORS_DETAILS],
  showMenu: true,
  keyPermission: 'vendors',
  onboardingDes: 'Find summaries and contact information of vendors',
};

export const ROUTE_VENDORS_MANAGEMENT_LIST = {
  key: 'vendors-management-list',
  path: '/vendors-management-list',
  // icon: 'IconVendors',
  //component: 'Vendors',
};

export const ROUTE_VENDORS_MANAGEMENT_DETAILS = {
  key: 'vendor-details',
  path: '/vendor-details/:id',
};

export const ROUTE_VENDORS_MANAGEMENT = {
  key: 'vendor-management',
  path: '/vendors-management',
  icon: 'IconVendors',
  component: 'Vendors',
  showMenu: true,
  keyPermission: 'vendor-management',
  onboardingDes: 'Manage all your potential partners from here',
  // nested: [ROUTE_VENDORS_MANAGEMENT_LIST],
};

export const ROUTE_EXPORTS = {
  key: 'exports',
  path: '/exports',
  icon: 'IconExports',
  component: 'Exports',
  showMenu: true,
  keyPermission: 'exports',
  onboardingDes: 'Check all your exports here',
};

//
export const ROUTE_CONTRACT_OPPORTUNITIES_DETAILS = {
  key: 'federal-contract-opportunities',
  path: '/contract-opportunities/:id',
};
export const ROUTE_CONTRACT_OPPORTUNITIES = {
  key: 'federal-contract-opportunities',
  path: '/contract-opportunities',
  icon: 'IconContractOpportunities',
  component: 'ContractOpportunities',
  nested: [ROUTE_CONTRACT_OPPORTUNITIES_DETAILS],
  showMenu: true,
  keyPermission: 'contract-opportunities',
  onboardingDes: 'Search across millions of contract opportunities',
};

//
export const ROUTE_GRANT_OPPORTUNITIES_DETAILS = {
  key: 'federal-grant-opportunities',
  path: '/grant-opportunities/:id',
};
export const ROUTE_GRANT_OPPORTUNITIES = {
  key: 'federal-grant-opportunities',
  path: '/grant-opportunities',
  icon: 'IconGrantOpportunities',
  component: 'GrantOpportunities',
  nested: [ROUTE_GRANT_OPPORTUNITIES_DETAILS],
  showMenu: true,
  keyPermission: 'grant-opportunities',
  onboardingDes: 'Explore and find the latest grant opportunities',
};

export const ROUTE_NSN_OPPORTUNITIES = {
  key: 'Federal NSN Opportunities',
  path: '/nsn-opportunities',
  icon: 'IconNsnOpportunities',
  component: 'NsnOpportunities',
  showMenu: true,
  keyPermission: 'nsn-opportunities',
  onboardingDes: 'Find Opportunities from the Department of Defense',
};

export const ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES_DETAILS = {
  key: 'state-local-contract-opportunities',
  path: '/state-local-contract-opportunities/:id',
};
export const ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES = {
  key: 'state-local-contract-opportunities',
  path: '/state-local-contract-opportunities',
  icon: 'IconLocalStateContractOpportunities',
  component: 'StateLocalContractOpportunities',
  nested: [ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES_DETAILS],
  showMenu: true,
  keyPermission: 'state-local-contract-opportunities',
  onboardingDes: 'Search across millions of state & local contract opportunities',
};

//
export const ROUTE_CONTRACT_AWARDS_DETAILS = {
  key: 'contract-awards',
  path: '/contract-awards/:id',
};
export const ROUTE_GRANT_AWARDS_DETAILS = {
  key: 'contract-awards',
  path: 'https://www.usaspending.gov/award/:id',
};
export const ROUTE_CONTRACT_AWARDS = {
  key: 'contract-awards',
  path: '/contract-awards',
  icon: 'IconContractAwards',
  component: 'ContractAwards',
  nested: [ROUTE_CONTRACT_AWARDS_DETAILS],
  showMenu: true,
  keyPermission: 'contract-awards',
  onboardingDes: 'Stay in the loop with the latest contracts awarded',
};

//
export const ROUTE_FEDERAL_AGENTS_DETAILS = {
  key: 'federal-agents',
  path: '/federal-agents/:id',
};
export const ROUTE_FEDERAL_AGENTS = {
  key: 'federal-agents',
  path: '/federal-agents',
  icon: 'IconFederalAgents',
  component: 'FederalAgents',
  subscribedOnly: true,
  nested: [ROUTE_FEDERAL_AGENTS_DETAILS],
  showMenu: true,
  keyPermission: 'federal-agents',
  onboardingDes: 'Find contact info for thousands of Federal Agents',
};

//
export const ROUTE_AGENCIES_OFFICES_DETAILS = {
  key: 'federal-agencies-offices',
  path: '/agencies-offices/:id',
};
export const ROUTE_AGENCIES_OFFICES = {
  key: 'federal-agencies-offices',
  path: '/agencies-offices',
  icon: 'IconLocation',
  component: 'FederalAgenciesAndOffices',
  nested: [ROUTE_AGENCIES_OFFICES_DETAILS],
  showMenu: true,
  keyPermission: 'federal-agencies-offices',
  onboardingDes: 'All opportunities and funding info for each agency',
};

//
export const ROUTE_GOVERMENT_FILES_DETAILS = {
  key: 'government-files',
  path: '/government-files/:id',
};
export const ROUTE_GOVERMENT_FILES = {
  key: 'government-files',
  path: '/government-files',
  icon: 'IconGovermentFiles',
  component: 'GovermentFiles',
  nested: [ROUTE_GOVERMENT_FILES_DETAILS],
  showMenu: true,
  keyPermission: 'government-files',
  onboardingDes: 'Read the latest documents from government sources',
};

//
export const ROUTE_CODE_CATEGORIES_DETAILS = {
  key: 'code-categories',
  path: '/code-categories/:id',
};
export const ROUTE_CODE_CATEGORIES = {
  key: 'code-categories',
  path: '/code-categories',
  icon: 'IconCodeCategories',
  component: 'CodeCategories',
  nested: [ROUTE_CODE_CATEGORIES_DETAILS],
  showMenu: true,
  keyPermission: 'code-categories',
  onboardingDes: 'Opportunities and relevant info by Industry Code',
};

export const ROUTE_RELEASES = {
  key: 'Releases',
  path: '/releases',
  icon: 'IconReleases',
  component: 'Releases',
  showMenu: true,
  keyPermission: 'releases',
};

export const ROUTE_LMS = {
  key: 'learning-center',
  path: 'none',
  function: 'goToLearnWorlds',
  icon: 'IconLearningCenter',
  component: 'Releases',
  showMenu: true,
  keyPermission: 'learning-center',
};

export const ROUTE_OVERVIEW = {
  key: 'Overview',
  path: '/client-portal-overview',
  icon: 'IconOverview',
  component: 'Business',
  showMenu: true,
  keyPermission: 'overview',
  onboardingDes: 'Determine the current state of your organization',
};

export const ROUTE_FORMS = {
  key: 'Forms',
  path: '/client-portal-forms',
  icon: 'IconForms',
  component: 'Business',
  showMenu: true,
  keyPermission: 'forms',
  onboardingDes: 'Visualize all the forms you have been assigned',
};

export const ROUTE_FORM_DETAIL = {
  key: 'form-detail',
  path: '/client-portal/forms/:id',
  component: 'FormDetail',
};

export const ROUTE_FORM_DETAIL_V2 = {
  key: 'form-detail',
  path: '/client-portal/forms-v2/:id',
  component: 'FormDetailv2',
};

export const ROUTE_DOCUMENTS = {
  key: 'Documents',
  path: '/client-portal-documents',
  icon: 'IconGovermentFiles',
  component: 'Business',
  showMenu: true,
  keyPermission: 'documents',
  onboardingDes: 'Manage your shared and requested documents',
};
export const ROUTE_SUPPORT_TICKET = {
  key: 'Service Support',
  path: '/client-portal-service-support',
  icon: 'IconSupport',
  component: 'Business',
  showMenu: true,
  keyPermission: 'support-tickets',
  onboardingDes: 'Visualize your opened and closed support tickets',
};

export const ROUTE_QUOTE_REQUESTS = {
  key: 'Requests',
  path: '/quote-requests',
  icon: 'IconQuoteRequests',
  component: 'QuoteRequests',
  showMenu: true,
  keyPermission: 'quote-requests',
  onboardingDes:
    'In this section you will find all the solicitations that you have requested a quote for and be able to proceed once you receive a response from the USFCR team.',
};

export const ROUTE_ONBOARDING_MEETING = {
  key: 'Live Training',
  path: '/onboarding-meeting',
  icon: 'IconOnboardingMeeting',
  component: 'OnboardingMeeting',
  showMenu: true,
  keyPermission: 'onboarding-meeting',
  onboardingDes: 'Schedule an onboarding meeting with a USFCR team member',
};

const WORK_PLACE_GROUP = {
  keyPermission: 'workplace',
  key: 'workplace',
  icon: 'IconWorkplace',
  onboardingDes: 'This is the place were you can organize your work and follow up on potential opportunities.',
  items: [
    ROUTE_DASHBOARD,
    ROUTE_DEMO_DASHBOARD,
    ROUTE_CALENDAR,
    ROUTE_PIPELINES,
    ROUTE_MY_PURSUITS,
    ROUTE_TASKS,
    ROUTE_PERSONAL_TASKS,
    ROUTE_SAVED_SEARCHES,
    ROUTE_TEAMING_OPPORTUNITIES,
    ROUTE_OPEN_PURSUITS,
    ROUTE_VENDORS_MANAGEMENT,
    ROUTE_EXPORTS,
    ROUTE_QUOTE_REQUESTS,
  ],
};

const DISCOVERY_GROUP = {
  keyPermission: 'discovery',
  key: 'discovery',
  icon: 'IconExplore',
  onboardingDes:
    'In this section you will find millions of documents with contracting opportunities, vendor details and federal agent’s contact information.',
  items: [
    ROUTE_VENDORS,
    ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES,
    ROUTE_CONTRACT_OPPORTUNITIES,
    ROUTE_GRANT_OPPORTUNITIES,
    ROUTE_NSN_OPPORTUNITIES,
    ROUTE_CONTRACT_AWARDS,
    ROUTE_FEDERAL_AGENTS,
    ROUTE_AGENCIES_OFFICES,
    ROUTE_GOVERMENT_FILES,
  ],
};

const BUSINESS_GROUP = {
  keyPermission: 'business',
  key: 'business',
  icon: 'IconBusiness',
  onboardingDes: 'Stay updated on the latest platform improvements',
  items: [ROUTE_OVERVIEW, ROUTE_FORMS, ROUTE_DOCUMENTS, ROUTE_SUPPORT_TICKET],
};

const KNOWLEDGE_GROUP = {
  keyPermission: 'knowledge',
  key: 'knowledge',
  icon: 'IconLearn',
  onboardingDes: 'Stay updated on the latest platform improvements',
  items: [ROUTE_CODE_CATEGORIES, ROUTE_LMS, ROUTE_RELEASES, ROUTE_ONBOARDING_MEETING],
};

export const ROUTE_SINGUPSUBSCRIBE = {
  key: 'singup-subscribe',
  path: '/singup-subscribe',
  component: 'SingupSubscribe',
};
export const APP_MENU = [WORK_PLACE_GROUP, DISCOVERY_GROUP, BUSINESS_GROUP, KNOWLEDGE_GROUP];
